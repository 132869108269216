<template>
  <div>
    <v-data-table
      :headers="atual.header"
      :search="search"
      :items="atual.getter"
      hide-default-footer
      no-data-text="Nenhum Fornecedor Cadastrado"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="green darken-4"
          class="mr-2"
          @click="
            () => {
              visibleItem(item);
            }
          "
          >mdi-eye</v-icon
        >
        <v-icon
          v-if="component.permission.Editar"
          color="yellow darken-4"
          class="mr-2"
          @click="
            () => {
              edit_item(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          v-if="component.permission.Excluir"
          color="red darken-4"
          class="mr-2"
          @click="
            () => {
              deleteItem(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- <Edit :component="this" ref="edit" /> -->
    <!-- <AddDialog :out_of_register="false"  /> -->
    <Delete :component="this" />
    <div>
      <v-divider class="mt-6"></v-divider>
      <v-pagination
        class="mb-3"
        v-model="atualCurrent"
        :length="atualTotal"
        color="success"
        :total-visible="10"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { headers } from "./script";
// import Edit from "./Edit/";
import Delete from "./Delete_Item/";
// import AddDialog from "../AddDialog/index.vue"
import {
  GET_ALL_PROVIDER,
  DESTROY_PROVIDER,
  ITEM_TO_UPDATE,
  GET_AUTOCOMPLETE_PROVIDER,
} from "../../../store/actions/provider";
import { mapGetters } from "vuex";
import { fixingPeopleCNPJ } from "../../Utils/global_functions/clean_cnpj_cpf";
export default {
  props: {
    component: Object(),
    search: String(),
  },
  components: { /*Edit,*/ Delete /*AddDialog*/ },
  computed: {
    ...mapGetters([
      "getPhisycalProvider",
      "getLegalProvider",
      "getShowDialogRegisterProvider",
      "getNewProviderToAdd",
      "getProviderPhysicalAuto",
      "getProviderLegalAuto",
    ]),
  },

  data: () => ({
    dialog: Boolean(),
    dialog_cancel: Boolean(),
    paginate: 1,
    items: Array(),
    headers,
    atual: {
      header: Array(),
      getter: Array(),
    },
    pagPhysicalTotal: Number(),
    pagPhysicalCurrent: Number(),

    pagLegalTotal: Number(),
    pagLegalCurrent: Number(),

    atualCurrent: Number(),
    atualTotal: Number(),
  }),

  methods: {
    visibleItem(item) {
      this.$store.dispatch(ITEM_TO_UPDATE, { item: item, justVisible: true });
      this.$store.dispatch("setShowDialogRegisterProvider", true);
    },
    pagPhysical(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header =
        this.headers[isLegal ? type.isLegal : type.isPhisical];

      this.atual.getter = isLegal
        ? fixingPeopleCNPJ(this.getLegalProvider) //this.getLegalProvider
        : this.getPhisycalProvider;

      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
      this.atualCurrent = isLegal
        ? this.pagLegalCurrent
        : this.pagPhysicalCurrent;
    },

    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_PROVIDER, {
        type_people: "PhysicalPeople",
        page: page,
        limit: 10,
        paginate: true,
      });
    },

    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_PROVIDER, {
        page: page,
        limit: 10,
        paginate: true,
        type_people: "LegalPeople",
      });
    },
    edit_item(item) {
      this.$store.dispatch(ITEM_TO_UPDATE, item);
      this.$store.dispatch("setShowDialogRegisterProvider", true);
    },
    // edit_item(item) {
    //   this.dialog = !this.dialog;
    //   if (item.type_people === "PhysicalPeople") {
    //     this.$refs.edit.register = false;
    //     this.$refs.edit.PhysicalPeople.id = item.id;
    //     this.$refs.edit.PhysicalPeople.type_people = item.type_people;
    //     this.$refs.edit.PhysicalPeople.enterprise_id = item.enterprise_id;

    //     this.$refs.edit.PhysicalPeople.name = item.name;
    //     this.$refs.edit.PhysicalPeople.lastName = item.lastName;
    //     this.$refs.edit.PhysicalPeople.cpf = item.cpf;
    //     this.$refs.edit.PhysicalPeople.gender = item.gender;
    //     this.$refs.edit.PhysicalPeople.emitter = item.emitter;
    //     this.$refs.edit.PhysicalPeople.emitterState = item.emitterState;
    //     this.$refs.edit.PhysicalPeople.idCard = item.idCard;
    //     this.$refs.edit.PhysicalPeople.dateOfBirth = item.dateOfBirth;
    //     this.$refs.edit.PhysicalPeople.fatherName = item.fatherName;
    //     this.$refs.edit.PhysicalPeople.motherName = item.motherName;
    //     this.$refs.edit.PhysicalPeople.nacionality = item.nacionality;
    //     this.$refs.edit.PhysicalPeople.naturality = item.naturality;
    //     this.$refs.edit.PhysicalPeople.monthlyIncome = item.monthlyIncome;
    //     this.$refs.edit.PhysicalPeople.civilStatus = item.civilStatus;
    //     this.$refs.edit.PhysicalPeople.spouse = item.spouse;
    //     this.$refs.edit.PhysicalPeople.icomeSpouse = item.icomeSpouse;

    //     this.$refs.edit.PhysicalPeople.relatedRepresentative =
    //       item.relatedRepresentative;
    //     this.$refs.edit.PhysicalPeople.type = item.type;
    //   } else if (item.type_people === "LegalPeople") {
    //     this.$refs.edit.register = true;
    //     this.$refs.edit.LegalPeople.id = item.id;
    //     this.$refs.edit.LegalPeople.type_people = item.type_people;
    //     this.$refs.edit.LegalPeople.enterprise_id = item.enterprise_id;
    //     this.$refs.edit.LegalPeople.cnpj = item.cnpj;
    //     this.$refs.edit.LegalPeople.fantasyName = item.fantasyName;
    //     this.$refs.edit.LegalPeople.socialName = item.socialName;
    //     this.$refs.edit.LegalPeople.stateRegistration = item.stateRegistration;
    //     this.$refs.edit.LegalPeople.municipalRegistration =
    //       item.municipalRegistration;
    //     this.$refs.edit.LegalPeople.homePage = item.homePage;

    //     this.$refs.edit.LegalPeople.relatedRepresentative =
    //       item.relatedRepresentative;
    //     this.$refs.edit.LegalPeople.type = item.type;
    //   }
    //   this.contact_edit(item);
    //   this.address_edit(item);
    //   this.bank_edit(item);
    // },
    //Estruturar Arrays para Edição.
    contact_edit(item) {
      let contact = Object();
      let auxContact = Object();
      this.$refs.edit.ListContact = [];
      item.contacts.forEach((e) => {
        contact.id = e.id;
        contact.primaryEmail = e.primaryEmail;
        contact.secundaryEmail = e.secundaryEmail;
        contact.phone = e.phone;
        contact.cellPhone = e.cellPhone;
        contact.phoneReference = e.phoneReference;
        auxContact = { ...contact };
        this.$refs.edit.ListContact.push(auxContact);
      });
    },
    address_edit(item) {
      let address = Object();
      let auxAddress = Object();
      this.$refs.edit.ListAddress = [];
      item.address.forEach((e) => {
        address.id = e.id;
        address.street = e.street;
        address.number = e.number;
        address.district = e.district;
        address.complement = e.complement;
        address.city = e.city.name;
        address.state = e.state;
        address.cep = e.cep;
        address.city_id = e.city_id;
        auxAddress = { ...address };
        this.$refs.edit.ListAddress.push(auxAddress);
      });
    },
    bank_edit(item) {
      let banks = Object();
      let auxBank = Object();
      this.$refs.edit.ListBank = [];
      item.banks.forEach((e) => {
        banks.id = e.id;
        banks.bank = e.bank;
        banks.agency = e.agency;
        banks.count = e.count;
        banks.manager = e.manager;
        auxBank = { ...banks };
        this.$refs.edit.ListBank.push(auxBank);
      });
    },
    deleteItem(item) {
      this.dialog_cancel = !this.dialog_cancel;
      this.ObjectToDestroy = { ...item };
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_PROVIDER, this.ObjectToDestroy);
      } catch (e) {
        alert(e);
      } finally {
        this.dialog_cancel = !this.dialog_cancel;
      }
    },
    async AutocompleteProviderPhysical(page = 1) {
      if (this.search == "") {
        this.GetAllPhysical();
      } else {
        await this.$store.dispatch(GET_AUTOCOMPLETE_PROVIDER, {
          type_people: "PhysicalPeople",
          enterprise_id: this.component.enterpriseId,
          search: this.search,
          page: page,
          limit: null,
          paginate: false,
        });
      }
    },

    async AutocompleteProviderLegal(page = 1) {
      if (this.component.search == "") {
        this.GetAllLegal();
      } else {
        await this.$store.dispatch(GET_AUTOCOMPLETE_PROVIDER, {
          type_people: "LegalPeople",
          enterprise_id: this.component.enterpriseId,
          search: this.component.search,
          page: page,
          limit: null,
          paginate: false,
        });
      }
    },
  },

  created() {
    this.GetAllPhysical();
    this.GetAllLegal();
  },

  watch: {
    getNewProviderToAdd: function () {
      this.GetAllPhysical();
      this.GetAllLegal();
    },
    getPhisycalProvider: function (val) {
      this.pagPhysicalTotal = val[0].page.last_page;
      this.pagPhysicalCurrent = val[0].page.current_page;
      this.pagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
    },

    getLegalProvider: function (val) {
      this.pagLegalTotal = val[0].page.last_page;
      this.pagLegalCurrent = val[0].page.current_page;
    },
    atualCurrent: function (val) {
      if (this.component.isFormalPeople) {
        this.GetAllLegal && this.GetAllLegal(val);
      } else {
        this.GetAllPhysical && this.GetAllPhysical(val);
      }
    },
    search: function () {
      this.AutocompleteProviderPhysical();
      this.AutocompleteProviderLegal();
    },
  },
  mounted() {
    this.isLegal();

    this.$watch(
      () => this.component.isFormalPeople,
      function (val) {
        this.isLegal(val);
      }
    );
  },
};
</script>
